import PlayerConfig from '@optussport/fe-bitmovin-player/lib/types/PlayerConfig';
import { FeaturesType } from '@optussport/fe-bitmovin-player/lib/types/Features';
import { SpeedType } from '../player.d';
import { CONFIG } from 'src/config/config';
import {
    HttpRequestType,
    HttpResponse,
    HttpResponseBody,
    LogLevel,
    PlayerEvent,
} from '@optussport/fe-bitmovin-player';
import { getActivePlayer } from '../utilities/player';

export const featuresConfig: FeaturesType = {
    enableSaveUserSettings: false, // Enable/disable saving and applying the player volume/mute and video quality across user sessions and for all assets played.
    yspPlaybackPolicy: {
        enabled: false, // Enable/disable the use of custom playback policy for skipping ads and seeking behaviour. Needs to be enabled for ad holiday to work.
        allowSeekPastAds: false, // Allow user to seek forward so that the ads in between the current position and seek position will not be played after seeking.
    },
    adHoliday: {
        enabled: CONFIG.adHolidayEnabled, // Enable/disable ad holiday countdown calculation
        duration: CONFIG.adHolidayDuration, // 180 seconds = 3 mins
        allowSkipButton: false, // Show/hide skip button during an ad break if user is inside the ad holiday period
    },
};

export const bPlayerConfig: PlayerConfig = {
    key: '091F8084-B544-4DAB-B090-94ECF8506D12',
    features: featuresConfig,
    ui: false,
    playback: {
        autoplay: true,
        videoCodecPriority: ['avc', 'av1', 'hevc'],
    },
    adaptation: {
        maxStartupBitrate: '2000kbps',

        onVideoAdaptation: ({ representations, suggested }) => {
            const player = getActivePlayer();
            const { bitrate: userBitrate = Number.POSITIVE_INFINITY } =
                player.getQualities().find((q) => q.selected) || {};
            const { bandwidth: suggestedBitrate = Number.POSITIVE_INFINITY } =
                representations.find((r) => r.id === suggested) || {};

            const maxBitrate = Math.min(userBitrate, suggestedBitrate);

            const { id = suggested } =
                [...representations.sort((a, b) => b.bandwidth - a.bandwidth)].find(
                    (r) => r.bandwidth <= maxBitrate
                ) || {};

            return id;
        },
    },
    buffer: {
        video: {
            forwardduration: 16,
            backwardduration: 16,
        },
        audio: {
            forwardduration: 16,
            backwardduration: 16,
        },
    },
    tweaks: {
        startup_threshold: 0.5,
    },
    network: {
        // preprocessHttpRequest: (type, request) => {
        //     if (type === HttpRequestType.MANIFEST_DASH) {
        //         console.info('REQUEST TYPE >>>', type);
        //         console.log('REQUEST HEADER >>>>', request.headers);
        //         console.log('REQUEST MADE >>>>', request.url, request.body);
        //     }
        //     if (type === HttpRequestType.DRM_LICENSE_PLAYREADY) {
        //         console.info('REQUEST TYPE >>>', type);
        //         console.log('REQUEST HEADER >>>>', request.headers);
        //         // console.log(
        //         //     'REQUEST MADE >>>>',
        //         //     request.url,
        //         //     request.body && request.body.toString()
        //         // );
        //     }
        //     return Promise.resolve(request);
        // },
        // preprocessHttpResponse: (type, response) => {
        //     // function processResponseBody<T extends HttpResponseBody>(
        //     //     response: HttpResponse<HttpResponseBody>
        //     // ): void {
        //     //     // Check if response.body is undefined first
        //     //     if (response.body === undefined) {
        //     //         return;
        //     //     }
        //     //     if (typeof response.body === 'string') {
        //     //         console.log('RESPONSE BODY (String) >>>>', response.body);
        //     //     } else if (response.body instanceof Buffer) {
        //     //         console.log('RESPONSE BODY (Buffer) >>>>', response.body.toString('utf-8'));
        //     //     } else if (typeof response.body === 'object' && response.body !== null) {
        //     //         console.log(
        //     //             'RESPONSE BODY (Buffer) >>>>',
        //     //             JSON.parse(JSON.stringify(response.body))
        //     //         );
        //     //     }
        //     // }
        //     function processResponseBody<T extends HttpResponseBody>(
        //         response: HttpResponse<HttpResponseBody>
        //     ): T | undefined {
        //         // Check if response.body is undefined first
        //         if (response.body === undefined) {
        //             return;
        //         }
        //         if (typeof response.body === 'string') {
        //             return response.body.replaceAll(
        //                 'ContentProtection',
        //                 'IShallNotBeParsed'
        //             ) as unknown as T;
        //         } else if (response.body instanceof Buffer) {
        //             return Buffer.from(
        //                 response.body
        //                     .toString('utf-8')
        //                     .replaceAll('ContentProtection', 'IShallNotBeParsed')
        //             ) as unknown as T;
        //         } else if (typeof response.body === 'object' && response.body !== null) {
        //             return JSON.parse(
        //                 JSON.stringify(response.body).replaceAll(
        //                     'ContentProtection',
        //                     'IShallNotBeParsed'
        //                 )
        //             ) as unknown as T;
        //         } else {
        //             return;
        //         }
        //     }
        //     if (type === HttpRequestType.DRM_LICENSE_PLAYREADY) {
        //         console.log('RESPONSE HEADER >>>>', response.headers);
        //     }
        //     if (type === HttpRequestType.MANIFEST_DASH) {
        //         console.info('RESPONSE TYPE >>>', type);
        //         console.log('RESPONSE HEADER >>>>', response.headers);
        //         // response.body = processResponseBody(response);
        //     }
        //     return Promise.resolve(response);
        // },
    },
    // events: {
    //     [PlayerEvent.DownloadFinished]: (event: any) => {
    //         if (
    //             event.downloadType === HttpRequestType.DRM_LICENSE_PLAYREADY &&
    //             (event.httpStatus === 400 || event.httpStatus === 500)
    //         ) {
    //             console.warn('Reloaded player due to error loading DRM License', event);
    //         }
    //         if (event.downloadType === HttpRequestType.DRM_LICENSE_PLAYREADY) {
    //             console.warn('PlayerEvent.DownloadFinished >>>> PLAYREADY', event);
    //         }
    //     },
    //     [PlayerEvent.SourceLoaded]: (data) => {
    //         console.log('PlayerEvent.SourceLoaded >> ', data);
    //     },
    //     [PlayerEvent.LicenseValidated]: (data) => {
    //         console.info('PlayerEvent.LicenseValidated >>', data);
    //     },
    //     [PlayerEvent.DrmLicenseAdded]: (data) => {
    //         console.info('PlayerEvent.DrmLicenseAdded >>', data);
    //     },
    // },
    logs: {
        level: LogLevel.WARN,
    },
};

export const speeds: SpeedType[] = [
    {
        id: '0',
        label: '50%',
        speed: 0.5,
        selected: false,
    },
    {
        id: '1',
        label: 'Normal',
        speed: 1,
        selected: false,
    },
    {
        id: '2',
        label: '150%',
        speed: 1.5,
        selected: false,
    },
    {
        id: '3',
        label: '200%',
        speed: 2,
        selected: false,
    },
];
