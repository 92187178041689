import { Config, StringSet, URLSet } from '../config';
import { env } from './config.all';

// TODO: Update with Fetch Segment URLs
const segmentFetchURLs: URLSet = {
    PROD: 'https://cdn.segment.com/analytics.js/v1/GqHwwhJ7Rg2Zg8Pwcr6eiEuqnPORgHd0/analytics.min.js',
    PP: 'https://cdn.segment.com/analytics.js/v1/kD7mjFc2Qjny3TPHbBvazY3YjqW0fhGX/analytics.min.js',
    STAGING:
        'https://cdn.segment.com/analytics.js/v1/kD7mjFc2Qjny3TPHbBvazY3YjqW0fhGX/analytics.min.js',
    LOCAL: 'https://cdn.segment.com/analytics.js/v1/kD7mjFc2Qjny3TPHbBvazY3YjqW0fhGX/analytics.min.js',
};

// TODO: Update with Fetch DR Files
const drModeFetchURLs: URLSet = {
    PROD: 'https://dr.optussport.tv/assets-ctv.json',
    PP: 'https://dr.optussport.info/assets-ctv.json',
    STAGING: 'https://dr.optussport.info/assets-ctv.json',
    LOCAL: 'https://dr.optussport.info/assets-ctv.json',
};

// TODO: Update with Fetch Braze URLs
const brazeFetchKeys: StringSet = {
    PROD: '25382c82-0f10-4999-a4bb-15d5678cb1ad',
    PP: '27f41786-fa12-41a5-a06a-b68bd9b41da9',
    STAGING: '27f41786-fa12-41a5-a06a-b68bd9b41da9',
    LOCAL: '27f41786-fa12-41a5-a06a-b68bd9b41da9',
};

const config_fetch: Partial<Config> = {
    platform: 'stb',
    segmentLibUrl: segmentFetchURLs[env],
    drModeCheckURL: drModeFetchURLs[env],
    noAnimations: true,
    exitAppPopupEnabled: true,

    playbackDrmChoice: 'playready',
    playbackSupportsCmafLive: false,
    playbackSupportsCmafVod: false,
    YoSpaceActiveOnLive: false,
    YoSpaceActiveOnVod: false,

    BrazeApiKey: brazeFetchKeys[env],

    contentCardCMSActive: true,

    bitmovinPlayerConfig: {
        playback: {
            autoplay: true,
            videoCodecPriority: ['avc', 'av1', 'hevc'],
        },
        tweaks: {
            // dword_base_media_decode_timestamps: true,
        },
    },
};

// const config_fetch = (version: string) => combineConfig(config_fetch_version_specific, version);
export default config_fetch;
