import { DateTime } from 'luxon';
import { VIEW_ALL_CARD } from 'src/constants/assetTypes';
import {
    AccessGroupType,
    AssetType,
    EPGEventDataType,
    LiveAssetType,
    PlaybackType,
    WatchModeLiveType,
    WatchModeType,
} from 'src/state/stores/dashboard/dashboard';
import { isLayoutHero } from './dashboard';
import { CONFIG } from 'src/config/config';
import { processImageUrl } from './url';
import { UserDataType } from 'src/state/stores/user/user';

import stockBackground from 'src/assets/images/backgrounds/stockBackground.png';
import { getAppData } from 'src/lib/Analytics/Segment/utils';

export const formatAssetPlaybackData = (
    data: any,
    watchMode: WatchModeType | WatchModeLiveType = 'beginning'
) => {
    const {
        items: {
            item: [
                { url, license, protocol, selectedCDN, encodingType, videoLanguage, videoJobID },
            ],
        },
        live,
        isSSAIEnabled,
        streamType,
        playbackStatus,
        drmProtected,
    } = data;

    let playbackLicense = undefined;
    if (drmProtected && license) {
        const {
            drm: { license: allLicenses = [] },
        } = license;

        const selectedLicense = Array.isArray(allLicenses)
            ? allLicenses.find((l: any) => l['@name'] === license['@name'])
            : allLicenses;
        if (selectedLicense && selectedLicense['@name'] === license['@name']) {
            playbackLicense = {
                licenseUrl: `${selectedLicense['@uri']}`,
                name: selectedLicense['@name'],
                uri: selectedLicense['@uri'],
                drmData: selectedLicense['drmData'],
            };
        }
    }

    const playback: PlaybackType = {
        items: [
            {
                url,
                videoJobID,
                encodingType,
                videoLanguage,
                selectedCDN,
                protocol,
                license: playbackLicense,
            },
        ],
        isLive: live,
        isSSAIEnabled,
        streamType,
        playbackStatus,
        watchMode,
    };

    return playback;
};

export const formatLiveAssetPlaybackData = (data: any) => {
    const { epg } = data;

    // TODO: Improve the logic on when no epg data is available.
    // Need a fail-safe fallback.
    if (Object.keys(epg).length === 0) return;

    const {
        'DVB-EPG': {
            Service: { Event },
        },
    } = epg;

    return Event.map((e: any) => {
        const mE: EPGEventDataType = {
            /**
             * The time coming from the API is in UTC, however it's missing the trailing 'Z'.
             * This means that when converting it's not treated as UTC but local time.
             * The following allows us to work around this
             **/
            epgStart: DateTime.fromISO(e.epgStart)
                .setZone('UTC', { keepLocalTime: true })
                .toMillis(),
            epgStop: DateTime.fromISO(e.epgStop).setZone('UTC', { keepLocalTime: true }).toMillis(),
            id: e.id,
            shortEventDescription: e.ShortEventDescriptor.Text?._,
            title: e.ShortEventDescriptor.EventName?._,
        };
        return mE;
    });
};

export const showOverlayAndLeftAlignActionsBasedOnTags = (asset: AssetType) => {
    const getManualKey = asset.cmsTags?.find((tag) => tag.key === 'MANUAL');

    if (getManualKey) {
        if (getManualKey.values.includes('show_overlay')) {
            return true;
        }
        if (getManualKey.values.includes('no_overlay')) {
            return false;
        }
    }

    const assetTypesCheck = ['show', 'minimatch', 'highlights', 'mega', 'extended', 'game'];

    if (assetTypesCheck.includes(asset.type)) {
        return false;
    }

    return true;
};

export const shouldDisableStartover = (asset: AssetType) => {
    const getPlaybackKey = asset.cmsTags?.find((tag) => tag.key === 'PLAYBACK');
    const { enabledStartOverForLive } = CONFIG;

    if (enabledStartOverForLive === 'FOR_ALL') {
        return false;
    } else if (enabledStartOverForLive === 'FOR_NONE') {
        return true;
    } else if (enabledStartOverForLive === 'USE_ASSET_TAG' && getPlaybackKey) {
        if (getPlaybackKey.values.includes('no_startover')) {
            return true;
        }
    }

    return false;
};

export const shouldDisableTrickplay = (asset?: AssetType | null, streamType?: string | '') => {
    if (!asset) return;

    const { trickplayOnLive, trickplayOnStartOver } = CONFIG;
    const getPlaybackKey = asset.cmsTags?.find((tag) => tag.key === 'PLAYBACK');
    const isLive = streamType === 'live' || streamType === 'ssai_live';
    const isStartOver = streamType === 'dvr' || streamType === 'ssai_dvr';

    if (isLive) {
        if (trickplayOnLive === 'FOR_ALL') {
            return false;
        } else if (trickplayOnLive === 'FOR_NONE') {
            return true;
        } else if (trickplayOnLive === 'USE_ASSET_TAG' && getPlaybackKey) {
            if (getPlaybackKey.values.includes('no_trickplay_for_live')) {
                return true;
            }
        }
    }

    if (isStartOver) {
        if (trickplayOnStartOver === 'FOR_ALL') {
            return false;
        } else if (trickplayOnStartOver === 'FOR_NONE') {
            return true;
        } else if (trickplayOnStartOver === 'USE_ASSET_TAG' && getPlaybackKey) {
            if (getPlaybackKey.values.includes('no_trickplay_for_startover')) {
                return true;
            }
        }
    }

    // @NOTE: Keep for now incase of rollback required.
    // if (getPlaybackKey) {
    //     const disableForLive = isLive && getPlaybackKey.values.includes('no_trickplay_for_live');
    //     const disableForStartover =
    //         isStartOver && getPlaybackKey.values.includes('no_trickplay_for_startover');

    //     if (disableForLive || disableForStartover) {
    //         return true;
    //     }
    // }

    return false;
};

export const isAssetPremium = (accessGroups: AccessGroupType[]) => {
    return accessGroups.length === 1 && accessGroups.includes('PREMIUM');
};

/**
 * This function checks if an asset should show the free badge
 * Rules are:
 * for a premium user no free tags are shown for any of the assets
 * for a basic user:
 *          if fitness, no tags are shown for any of the assets
 *          if normal, free tag is shown for every assets except "Premium"
 * for a logged out user:
 *          if fitness, All free assets and "Premium" assets with "FREE_WITHOUT_LOGIN" tag has the free tag
 *          if normal, free tag is shown for every assets except "Premium"
 * @param assetType
 * @param accessGroups
 * @param user
 * @returns
 */

export const shouldShowFreeBadge = (
    assetType: any,
    accessGroups: AccessGroupType[],
    user: any
): boolean => {
    // view all asset
    if (assetType === VIEW_ALL_CARD) return false;
    // premium user
    if (user?.type && user.type.toUpperCase() === 'PREMIUM') {
        // hide for premium users
        return false;
    }
    // basic and logged out user
    return user // logged in
        ? assetType === 'fitness'
            ? false //logged in fitness
            : !isAssetPremium(accessGroups || []) // logged in non fitness
        : assetType === 'fitness' // logged out fitness
        ? accessGroups.includes('PREMIUM') // logged out fitness premium
            ? accessGroups.includes('FREE_WITHOUT_LOGIN') // premium logged out fitness
            : !isAssetPremium(accessGroups || []) //  non premium logged out fitness
        : !isAssetPremium(accessGroups || []); // logged out non fitness
};

export const showFreeBadge = (
    assetType: any,
    accessGroups: AccessGroupType[],
    user: any,
    cardLayout: string
): boolean => {
    return shouldShowFreeBadge(assetType, accessGroups, user) && !isLayoutHero(cardLayout);
};

export const getLivePoster = (asset: LiveAssetType) => {
    return (
        (asset['imageUrl_16:9'] && processImageUrl(asset['imageUrl_16:9'])) ||
        asset?.imageUrl ||
        stockBackground
    );
};

export const getLiveSegmentData = (user: UserDataType, asset: LiveAssetType, playbackData: any) => {
    return {
        appData: getAppData(),
        userData: { ...user, loggedIn: !!user },
        assetData: asset,
        playbackData: playbackData,
    };
};

export const validateAsset = (asset: AssetType) => {
    return asset.type !== 'article';
};

export const isMatchAsset = (asset: AssetType) => {
    const isMatch = ['game', 'mega', 'minimatch', 'extended', 'highlights'];
    return isMatch.indexOf(asset.type) !== -1 && asset.match;
};
