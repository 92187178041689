import { HashRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Dashboard from 'src/pages/DashBoard';
import DisasterRecovery from 'src/pages/DisasterRecovery';
import DisasterRecoveryPlayer from 'src/pages/DRPlayback';
import LivePlayback from 'src/pages/LivePlayback';
import Login from 'src/pages/Login';
import Logout from 'src/pages/Logout';
import GlobalLogout from 'src/pages/GlobalLogout';
import Onboarding from 'src/pages/Onboarding';
import Settings from 'src/pages/Settings';
import Splash from 'src/pages/Splash';
import UserWelcome from 'src/pages/Login/userWelcome';
import VodDetail from 'src/pages/VodDetail';
import VodPlayback from 'src/pages/VodPlayback';

import RouteTags, { DASHBOARD_ROOT } from './routeTags';
import Theme from 'src/components/Theme';

import FullscreenPopup from 'src/components/ui/FullscreenPopup';
import PlayerScreen from 'src/lib/bitmovin/components/PlayerScreen';

import BrazeProvider from 'src/lib/braze/context';

import { useDeepLinking } from 'src/controllers/deeplinking.controller';
import useAnalytics from 'src/controllers/analytics/analytics.controller';
import useAppReady from 'src/controllers/app/app.ready.controller';
import useAppSuspend from 'src/controllers/visiblityChange.controller';
import useBackButton from 'src/controllers/keyPress/backButton.controller';
import useDataRefreshOnNavigation from 'src/controllers/refreshDataOnNavigation.controller';
import useDataRefreshOnTimeout from 'src/controllers/refreshDataOnTimeout.controller';
import useDisasterMode from 'src/controllers/disasterRecovery.controller';
import useNetworkError from 'src/controllers/networkErrorController';
import platform from 'platform';
import useRouteAnalytics from 'src/controllers/analytics/analytics.routes.controller ';
import Braze from 'src/lib/braze/Components';

interface LocationStateType {
    redirectOnSuccess: {
        pathname: string;
    };
}

const Switches = () => {
    const navigate = useNavigate();
    const { pathname, state: locationState } = useLocation();

    useAnalytics();
    useRouteAnalytics();

    useAppSuspend();
    useDataRefreshOnNavigation();
    useDataRefreshOnTimeout();
    useDeepLinking();

    const { isDisasterMode } = useDisasterMode();
    const { ready } = useAppReady();
    const { networkError, clearError, showError } = useNetworkError();

    useBackButton({
        callBack: () => {
            if (
                locationState &&
                (locationState as LocationStateType).redirectOnSuccess?.pathname.includes('/live')
            ) {
                // This is only executed upon login - and only when user is presented login screen upon clicking on live tv(as a non logged in user)
                navigate(-2);
                return true;
            }
            if (pathname !== DASHBOARD_ROOT) {
                navigate(-1);
            }
            return true;
        },
        id: 'root',
        rank: 0,
    });

    return ready ? (
        networkError && showError ? (
            <FullscreenPopup id={'app.noInternet'} onClick={clearError} onExit={clearError} />
        ) : isDisasterMode ? (
            <Routes>
                <Route path={RouteTags.drplayer} element={<DisasterRecoveryPlayer />} />
                <Route path="*" element={<DisasterRecovery />} />
            </Routes>
        ) : (
            <Routes>
                <Route path={RouteTags.root} element={<Splash />} />
                <Route path={RouteTags.splash} element={<Splash />} />
                <Route path={RouteTags.onboarding} element={<Onboarding />} />
                <Route path={RouteTags.login} element={<Login />} />
                <Route path={RouteTags.logout} element={<Logout />} />
                <Route path={RouteTags.globalLogout} element={<GlobalLogout />} />
                <Route path={RouteTags.userWelcome} element={<UserWelcome />} />
                <Route path={RouteTags.dashboard} element={<Dashboard />} />
                <Route path={RouteTags.dashboardExplore} element={<Dashboard />} />
                <Route path={RouteTags.browse} element={<Dashboard />} />
                <Route path={RouteTags.browseExplore} element={<Dashboard />} />
                <Route path={RouteTags.dashboardViewAll} element={<Dashboard />} />
                <Route path={RouteTags.dashboardExploreViewAll} element={<Dashboard />} />
                <Route path={RouteTags.vodDetail} element={<VodDetail />} />
                <Route path={RouteTags.vodPlayback} element={<VodPlayback />} />
                <Route path={RouteTags.livePlayback} element={<LivePlayback />} />
                <Route path={RouteTags.settings} element={<Settings />} />
            </Routes>
        )
    ) : (
        <Splash />
    );
};

function Router() {
    return (
        <HashRouter>
            <Theme>
                <BrazeProvider>
                    <PlayerScreen />
                    <Braze />
                    <Switches />
                </BrazeProvider>
            </Theme>
        </HashRouter>
    );
}
export default Router;
