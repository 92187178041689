export const fetchTvDeviceModels = {
    GEN3_MINI: 'Gen 3 Mini',
    GEN3_MIGHTY: 'Gen 3 Mighty',
    GEN4_MINI: 'Gen 4 Mini',
    GEN4_MIGHTY: 'Gen 4 Mighty',
    GEN5_MINI: 'Gen 5 Mini',
} as const;

const fetchTVRegEx = /\(FETCHTV, ([^,]+)(?:, ([^\)]+))?\)/gi;

const mapModel = (model: string | null) => {
    switch (model) {
        // Hybroad
        case 'H626T': {
            // Gen 3 Mini
            return fetchTvDeviceModels.GEN3_MINI;
        }
        case 'M616T': {
            // Gen 3 Mighty
            return fetchTvDeviceModels.GEN3_MIGHTY;
        }
        case 'H671T': {
            // Gen 4 Mini
            return fetchTvDeviceModels.GEN4_MINI;
        }
        case 'M681T': {
            // Gen 4 Mighty
            return fetchTvDeviceModels.GEN4_MIGHTY;
        }
        // RDK/Skyworth
        case 'HY4424': {
            // Gen 5 Mini
            return fetchTvDeviceModels.GEN5_MINI;
        }
        default:
            return '';
    }
};

// NOTE: All the Fetch UA Strings
// 'Takin/3.0.0 (Linux arm ; U; en), FETCHTV_STB_BCM7218/3.7.11135 (FETCHTV, M616T, Wired)',
// 'Takin/3.0.0 (Linux arm ; U; en), FETCHTV_STB_BCM7218/3.7.11135 (FETCHTV, H626T, Wired)',
// 'Takin/3.0.0 (Linux arm ; U; en), FETCHTV_STB_BCM7218/3.7.11135 (FETCHTV, M681T, Wired)',
// 'Takin/3.0.0 (Linux arm ; U; en), FETCHTV_STB_BCM7218/3.7.11135 (FETCHTV, H671T, Wired)',
// 'WPE/2.28.7 (Linux arm ; U; en), FetchTV_STB_AMLS905X4/5.2.2714(FetchTV, HY4424)'

const getFetchTvDevice = (uastring: string) => {
    // Extract the firmware version
    const firmwareVersionMatch = uastring.match(/STB_[^\/]+\/([\d.]+)/);
    const firmwareVersion = firmwareVersionMatch ? firmwareVersionMatch[1] : null;

    // Extract the model
    const fetchTVMatch = fetchTVRegEx.exec(uastring);
    const model = fetchTVMatch ? fetchTVMatch[1] : null;
    const fetchModelName = mapModel(model);

    return {
        device: {
            model: fetchModelName,
            vendor: 'Fetch',
        },
        os: {
            version: firmwareVersion,
        },
    };
};
const fetchDeviceInfo = getFetchTvDevice(navigator.userAgent);
console.log('>> THE FETCH DEVICE INFO: ', JSON.stringify(fetchDeviceInfo));
export const isFetchTv = () => {
    return fetchTVRegEx.test(navigator.userAgent);
};

export const getFetchTvModel = () => {
    return fetchDeviceInfo.device.model;
};

export const getFetchTvOSVersion = () => {
    return fetchDeviceInfo.os.version;
};
