import { v4 as uuidv4 } from 'uuid';
import Storage from './storage';

import { pxScale } from './styles';
import { Windows } from './platform';
import { getTIFA, getTizenDevice, getTizenVersion, isTizen } from './tizen';
import { getLGUDID, isWebos } from './webos';
import platform from 'platform';
import { getFoxtelModel, getFoxtelOSVersion, isFoxtel } from './foxtel';
import { isXbox } from './xbox';
import { isPS4 } from './ps4';
import { isPS5 } from './ps5';
import { CONFIG } from 'src/config/config';
import { Analytics } from 'src/lib/Analytics';
import { getVidaaDeviceMake, getVidaaDeviceModel, getVidaaOSVersion, isVidaaOS } from './vidaa';
import { getFetchTvModel, getFetchTvOSVersion, isFetchTv } from './fetch';

export const getDeviceId = () => {
    let deviceId = Storage.getItem('deviceId');

    // TODO: Remove the migration logic.
    let deviceDID = Storage.getItem('did') ?? '';
    let device = Storage.getItem('d') ?? {};

    // Migration from using 'd' to deviceId for holding device id
    if (deviceDID && deviceDID !== deviceId) {
        deviceId = deviceDID;
        Storage.setItem('deviceId', deviceId);
    }

    if (device && device.deviceId && device.deviceId !== deviceId) {
        deviceId = device.deviceId;
        Storage.setItem('deviceId', deviceId);
    }

    if (!deviceId) {
        deviceId = uuidv4();
        Storage.setItem('deviceId', deviceId);
    }

    return deviceId;
};

export const getApplicationName = () => {
    return 'Optus Sport';
};

export const getOSVendor = () => {
    if (isFetchTv()) return { os: 'fetch', vendor: 'Fetch' } as const;
    if (isFoxtel()) return { os: 'foxtel', vendor: 'Foxtel' } as const;
    if (isPS4() || isPS5()) return { os: 'playstation', vendor: 'Sony' } as const;
    if (isTizen()) return { os: 'tizen', vendor: 'Samsung' } as const;
    if (isVidaaOS()) return { os: 'vidaa', vendor: 'Hisense' } as const;
    if (isWebos()) return { os: 'webos', vendor: 'LG' } as const;
    if (isXbox()) return { os: 'xbox', vendor: 'Microsoft' } as const;
    return { os: 'ctv', vendor: 'CTV' } as const;
};

export const getPlatformReferral = (useCheckout: boolean = false) => {
    const vendor = getOSVendor().vendor;
    switch (vendor) {
        case 'Fetch':
            return 'fetch';
        case 'Hisense':
            return 'hisense';
        case 'LG':
            return 'lg';
        case 'Samsung':
            return 'samsung';
        case 'Sony':
            return 'playstation';
        // case 'Microsoft':
        //     return 'xbox';
        default:
            return useCheckout ? 'checkout' : 'connect';
    }
};

export const getDeviceDetails = (): {
    osName: string;
    osVersion: string;
    deviceMake: string;
    deviceModel: string;
} => {
    const oSVendor = getOSVendor();
    const operatingSystem = oSVendor.os;

    switch (operatingSystem) {
        case 'ctv':
            return {
                osName: platform.os?.family ?? 'N/A',
                osVersion: platform.os?.version ?? 'N/A',
                deviceModel: 'N/A',
                deviceMake: 'N/A',
            };
        case 'fetch':
            return {
                osName: 'Fetch',
                osVersion: getFetchTvOSVersion() ?? 'N/A',
                deviceMake: 'Fetch',
                deviceModel: getFetchTvModel() ?? 'N/A',
            };
        case 'foxtel':
            return {
                osName: 'foxtel',
                osVersion: getFoxtelOSVersion() ?? 'N/A',
                deviceMake: 'Foxtel',
                deviceModel: getFoxtelModel() ?? 'N/A',
            };
        case 'tizen':
            return {
                osName: 'Tizen',
                osVersion: getTizenVersion(),
                deviceModel: getTizenDevice() ?? 'N/A',
                deviceMake: 'Samsung',
            };
        case 'vidaa':
            return {
                osName: 'Vidaa',
                osVersion: getVidaaOSVersion(),
                deviceModel: getVidaaDeviceModel() ?? 'N/A',
                deviceMake: getVidaaDeviceMake() ?? 'Hisense',
            };
        case 'webos':
            const webOSDevice = Storage.getItem('p');
            return {
                osName: 'webOS',
                osVersion: webOSDevice?.sdkVersion,
                deviceModel: webOSDevice?.modelName,
                deviceMake: 'LG',
            };
        case 'xbox':
            return {
                osName: platform.os?.family ?? 'N/A',
                osVersion: platform.os?.version ?? 'N/A',
                deviceMake: platform.manufacturer ?? 'N/A',
                deviceModel: platform.product ?? 'N/A',
            };
        case 'playstation':
            return {
                osName: 'PlayStation',
                osVersion: isPS4() ? '4' : isPS5() ? '5' : 'N/A', //done
                deviceMake: platform.manufacturer ?? 'Sony', //done
                deviceModel: platform.product ?? 'N/A', //done
            };
        default:
            //https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
            const _exhaustiveCheck: never = operatingSystem;
            return _exhaustiveCheck;
    }
};
console.log({ deviceInfo: getDeviceDetails(), platform });

export const getAdvertParams = () => {
    const oSVendor = getOSVendor();
    const operatingSystem = oSVendor.os;

    let advertId = sessionStorage.getItem('adid');
    let advertIdType;

    if (operatingSystem === 'tizen') {
        advertId = getTIFA();
        advertIdType = 'tifa';
    } else if (operatingSystem === 'webos') {
        advertId = getLGUDID();
        advertIdType = 'lgudid';
    } else if (operatingSystem === 'xbox') {
        advertId = Windows.System.UserProfile.AdvertisingManager.advertisingId ?? null;
        advertIdType = 'msai';
    }

    return {
        advertId,
        advertConsent: advertId !== null,
        advertIdType,
    };
};

export const exitApp = () => {
    if (isPS4() || isPS5()) {
        return;
    }
    Analytics.logAppExit();
    window.open(window.location.href, '_self')!.close();
};

export const isCTV = () =>
    CONFIG.isLocal || isPS4() || isPS5() || isXbox() || isFoxtel() || isTizen() || isWebos();

export const isPS = () => isPS4() || isPS5();

export const screenHeight = pxScale(1080);

// Utility Function to match user's device in an array of device details
// Could be used for Device Id or Device model.
export const matchByUserDevice = (checklist: string[], targetDevice: string): boolean =>
    checklist.includes(targetDevice);
