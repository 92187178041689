import { useEffect, useRef } from 'react';
import { isKeyBackButton } from 'src/utilities/keys';
import { CONFIG } from 'src/config/config';

type IDStackType = {
    randomID: string;
    rank: number | null;
};
type Callback = () => boolean;

type CallBackFunctionsType = {
    [char: string]: Callback;
};

type UseBackButtonPropType = {
    callBack: Callback;
    id?: string;
    rank?: number | null;
};

type AddCallbackPropType = {
    randomID: string;
    callBack: Callback;
    rank: number | null;
};

const callBackFunctions: CallBackFunctionsType = {};
let callBackIDStack: IDStackType[] = [];

/* --------------------------------- NOTICE ------------------------------------
  The callBack function passed to backButtonController should return true if
  the propogation of function to be terminated at that level. By deafult it will
  keep on progating through all callBack Functons registered.
  ------------------------------------------------------------------------------ */

const getRandomIDArray = () => callBackIDStack.map((i) => i.randomID) || [];

const updateCallBackIDStackwithRank = (randomID: string, rank: number) => {
    let tempRankArray = callBackIDStack.map((i) => i.rank) || [];
    const filteredRankArrr = [];
    const filteredIDStack: IDStackType[] = [];

    const rankFilter = (i: number) => {
        const correspondingRankItem = tempRankArray[i] || null;
        const correspondingIdItem = callBackIDStack[i] || null;
        if (correspondingRankItem !== null) {
            filteredRankArrr.unshift(correspondingRankItem);
            filteredIDStack.unshift(correspondingIdItem);
            rankFilter(i - 1);
        }
    };
    rankFilter(tempRankArray.length - 1);

    filteredRankArrr.push(rank); // Adding new rank
    filteredIDStack.push({ randomID, rank }); // Adding new ID Object

    const sortedRankArray = filteredRankArrr.sort((a, b) => a - b);
    const newSortedIdArray: IDStackType[] = [];
    sortedRankArray.forEach((Rank) => {
        newSortedIdArray.push(filteredIDStack.filter(({ rank: r }) => r === Rank)[0]);
    });

    const croppedIDArray = callBackIDStack.slice(
        0,
        callBackIDStack.length - newSortedIdArray.length + 1
    );
    const croppedRankArr = tempRankArray.slice(
        0,
        tempRankArray.length - sortedRankArray.length + 1
    ); // Need only for debugging
    callBackIDStack = [...croppedIDArray, ...newSortedIdArray];
    tempRankArray = [...croppedRankArr, ...sortedRankArray]; // Need only for debugging
};

/** ------------------------ */

const addNewCallbackToStack = (props: AddCallbackPropType) => {
    const { randomID, callBack = () => false, rank = null } = props;

    if (getRandomIDArray().indexOf(randomID) === -1) {
        const lastCallbackIDStackItem = callBackIDStack[callBackIDStack.length - 1];
        if (rank !== null && lastCallbackIDStackItem && lastCallbackIDStackItem.rank !== null) {
            updateCallBackIDStackwithRank(randomID, rank);
        } else {
            callBackIDStack.push({ randomID, rank });
        }
    }
    callBackFunctions[randomID] = callBack;
};

const removeCallBack = (randomID: string) => {
    callBackIDStack = callBackIDStack.filter((c) => c.randomID !== randomID);
};

const executeBackPressFunctionality = (parentLevel = 0) => {
    const index = getRandomIDArray().length - (parentLevel + 1);
    const callBackFunctionKey = callBackIDStack[index] && callBackIDStack[index].randomID;
    const backFunction = callBackFunctions[callBackFunctionKey];
    let stopPropogation = false;
    if (backFunction && typeof backFunction === 'function') {
        stopPropogation = backFunction();
        if (!(stopPropogation === true)) {
            executeBackPressFunctionality(parentLevel + 1);
        }
    }
};

const handleKeyDown = (event: KeyboardEvent) => {
    if (isKeyBackButton(event)) {
        event.preventDefault();
        executeBackPressFunctionality();
    }
    // keyCode 403 represents the RED button on the remote.
    if (CONFIG.debug && (event.keyCode === 403 || event.key === 'r')) {
        window.location.reload();
    }
};

document.addEventListener('keydown', handleKeyDown);

export const removeKeyPressHandler = () => {
    document.removeEventListener('keydown', handleKeyDown);
};

const useBackButton = (props: UseBackButtonPropType) => {
    const { callBack, rank = null, id = null } = props;
    const randomID = useRef(id || Math.random().toString());
    useEffect(() => {
        addNewCallbackToStack({
            randomID: randomID.current,
            callBack,
            rank,
        });
    }, [callBack, rank]);
    useEffect(() => () => removeCallBack(randomID.current), []);
};

export default useBackButton;
